export const theme = {
  global: {
    colors: {
      brand: '#7ed6df',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};